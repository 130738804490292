import { styled } from "@mui/material"

export const ProductThumbnailPrint = styled("div")({
  "& > *": {
    boxShadow: `2px 2px 0 rgba(0,0,0,0.2)`,
  },
  "& > *:after": {
    position: "absolute",
    pointerEvents: "none",
    content: "''",
    top: "0",
    left: "0",
    right: "0",
    bottom: "0",
    boxShadow: "inset 0 0 0 1px rgba(255,255,255,.2)",
    backgroundImage:
      "-webkit-linear-gradient(-45deg, rgba(255,255,255,.4), rgba(255,255,255,.2) 50%, rgba(255,255,255,0) 50%)",
    WebkitMaskImage: "-webkit-linear-gradient(#000, transparent)",
  },
})
