import { Cloudinary } from "@cloudinary/url-gen"
import { setConfig, buildUrl as _buildUrl } from "cloudinary-build-url"
import { currentSchoolYear } from "../src/app/core/libs/dateTime"

export const CLOUD_NAME = "het-huis-van-vier"
export const UNSIGNED_UPLOAD_PRESET = "fotosopschool"

setConfig({
  storageType: "private",
  cloudName: CLOUD_NAME,
})

export const buildUrl = _buildUrl

export const cld = new Cloudinary({
  cloud: {
    cloudName: CLOUD_NAME,
  },
})

// https://cloudinary.com/documentation/image_upload_api_reference#upload_response
type UploadResponse = {
  asset_id: string
  public_id: string
  version: number
  version_id: string
  signature: string
  width: number
  height: number
  format: "jpg"
  resource_type: "image"
  created_at: string
  tags: []
  bytes: number
  type: "private"
  etag: string
  placeholder: false
  url: string
  secure_url: string
  access_mode: "public"
  existing: false
  original_filename: "blob"
}

export const uploadFile = (
  file: File,
  fileName: string,
  year: number = currentSchoolYear(),
  schoolSlug: string,
  schoolId: number,
  onProgress: (pctDone: number, response: UploadResponse) => void
) => {
  const POST_URL = `https://api.cloudinary.com/v1_1/${CLOUD_NAME}/auto/upload`

  const XUniqueUploadId = +new Date()

  ;(function processFile() {
    const size = file.size
    const sliceSize = 20000000
    let start = 0

    setTimeout(loop, 3)

    function loop() {
      let end = start + sliceSize

      if (end > size) {
        end = size
      }
      let s = slice(file, start, end)
      send(s, start, end - 1, size)
      if (end < size) {
        start += sliceSize
        setTimeout(loop, 3)
      }
    }
  })()

  function send(piece, start, end, size) {
    // console.log("start ", start)
    // console.log("end", end)
    // console.log("size", size)
    // console.log("pct", Math.round((end / size) * 100))

    const formdata = new FormData()
    // console.log(XUniqueUploadId)

    formdata.append("file", piece)
    formdata.append("cloud_name", CLOUD_NAME)
    formdata.append("upload_preset", UNSIGNED_UPLOAD_PRESET)
    formdata.append(
      "public_id",
      `${schoolSlug}-${schoolId}/${year}-${year + 1}/${removeFileExtension(fileName)}`
    )

    const xhr = new XMLHttpRequest()
    xhr.open("POST", POST_URL, false)
    xhr.setRequestHeader("X-Unique-Upload-Id", XUniqueUploadId.toString())
    xhr.setRequestHeader("Content-Range", "bytes " + start + "-" + end + "/" + size)

    xhr.onload = function () {
      onProgress(Math.round((end / size) * 100), JSON.parse(this.responseText))
    }

    xhr.send(formdata)

    // setTimeout(() => {
    //   onProgress(100, {
    //     asset_id: "21eb69a7b83e242e12513c21ef5691e8",
    //     public_id: "fotosopschool/2020-2021/k2-2021-floris_van_gompel-5-DSCF4991",
    //     version: 1646768444,
    //     version_id: "29fecccfb570ee6dc8bc5c1c80f88ced",
    //     signature: "ec62d09934acc8cc15c3923aa355c151846fd6c9",
    //     width: 2667,
    //     height: 4000,
    //     format: "jpg",
    //     resource_type: "image",
    //     created_at: "2022-03-08T19:40:44Z",
    //     tags: [],
    //     bytes: 1881673,
    //     type: "private",
    //     etag: "e2e77262104c80c775d3cd8de27a0ed2",
    //     placeholder: false,
    //     url: "http://res.cloudinary.com/het-huis-van-vier/image/private/s--TY0P11r4--/v1646768444/fotosopschool/2020-2021/k2-2021-floris_van_gompel-5-DSCF4991.jpg",
    //     secure_url:
    //       "https://res.cloudinary.com/het-huis-van-vier/image/private/s--TY0P11r4--/v1646768444/fotosopschool/2020-2021/k2-2021-floris_van_gompel-5-DSCF4991.jpg",
    //     access_mode: "public",
    //     existing: false,
    //     original_filename: "blob",
    //   })
    // }, 2000)
  }

  function slice(file: File, start: number, end: number) {
    // @ts-ignore
    const slice = file.mozSlice
      ? // @ts-ignore
        file.mozSlice
      : // @ts-ignore
      file.webkitSlice
      ? // @ts-ignore
        file.webkitSlice
      : file.slice
      ? file.slice
      : noop

    return slice.bind(file)(start, end)
  }

  function removeFileExtension(fileName: string) {
    return fileName.replace(/\.[^/.]+$/, "")
  }

  function noop() {}
}

// export async function getCloudinarySignature({ publicId, folder }) {
//   const timestamp = Math.round(new Date().getTime() / 1000);
//   const api_secret: string = getEnv('CLOUDINARY_SECRET') as string;
//
//   const params = {
//     public_id: publicId,
//     timestamp,
//     folder,
//   };
//
//   return cloud.utils.sign_request(params, { api_secret });
// }
