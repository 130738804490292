import { Photo, ProductVariant } from "db"

type TieredPricingTable = { maxQuantity: number | null; minQuantity: number; price: number }[]
type BaseOption = {
  photoId: number | null
  photo: Photo | null
  variant: (Partial<ProductVariant> & { tieredPricing: TieredPricingTable }) | null
  quantity: number
}
export const calculatePriceForProductOptions = <TOption extends BaseOption>(options: TOption[]) => {
  const onlyOptionsWithPhotos = options.filter(
    (option) => (Boolean(option.photo) || Boolean(option.photoId)) && Boolean(option.variant)
  )

  // All option that have exactly the same tiered pricing should be calculated together
  // in order to calculate the tiered pricing over multiple options.
  const optionsPerPricingTier = groupPerPricingTier(onlyOptionsWithPhotos)

  return optionsPerPricingTier.reduce((total, options) => {
    const quantity = onlyOptionsWithPhotos.reduce((total, option) => total + option.quantity, 0)
    const pricing =
      (onlyOptionsWithPhotos[0]?.variant?.tieredPricing || []).length > 0
        ? onlyOptionsWithPhotos[0]!.variant!.tieredPricing
        : [
            {
              minQuantity: 1,
              price: onlyOptionsWithPhotos[0]?.variant?.price ?? 0,
              maxQuantity: null,
            },
          ]

    return total + calculatePrice(quantity, pricing)
  }, 0)
}

export const groupPerPricingTier = <TOption extends BaseOption>(
  options: TOption[]
): TOption[][][] => {
  const grouped: Record<string, TOption[][]> = options.reduce((prev, option) => {
    const key =
      option.variant?.tieredPricing
        .map((pricing) => `${pricing.minQuantity}-${pricing.maxQuantity}-${pricing.price}`)
        .join("|") || "default"

    return {
      ...prev,
      [key]: prev[key] ? [...prev[key], option] : [option],
    }
  }, {})

  return Object.values(grouped)
}

export function calculatePrice(
  quantity: number,
  pricingTable: TieredPricingTable,
  defaultPrice: number = 1
) {
  if (pricingTable.length === 0) return quantity * defaultPrice

  return pricingTable.reduce((totalPrice, pricing) => {
    const itemsInTier =
      Math.min(pricing.maxQuantity || Infinity, quantity) - pricing.minQuantity + 1

    if (itemsInTier > 0) {
      return totalPrice + itemsInTier * pricing.price
    }

    return totalPrice
  }, 0)
}

type TieredPricingTableWithMargin = (TieredPricingTable[number] & { schoolPct?: number | null })[]

export function calculatePriceWithMargin(
  quantity: number,
  pricingTable: TieredPricingTableWithMargin,
  defaultPrice: number = 1,
  defaultMargin: number | null = 0
) {
  if (pricingTable.length === 0) {
    const total = quantity * defaultPrice
    return { total, margin: total * ((defaultMargin ?? 0) / 100) }
  }

  return pricingTable.reduce(
    (totalPrice, pricing) => {
      const itemsInTier =
        Math.min(pricing.maxQuantity || Infinity, quantity) - pricing.minQuantity + 1

      if (itemsInTier > 0) {
        const price = itemsInTier * pricing.price
        const pct =
          (typeof pricing.schoolPct === "number" ? pricing.schoolPct : defaultMargin ?? 0) / 100

        return {
          total: totalPrice.total + price,
          margin: totalPrice.margin + price * pct,
        }
      }

      return totalPrice
    },
    { total: 0, margin: 0 }
  )
}
