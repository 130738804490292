const EXPIRATION = 60 * 60 * 24 // 1 day

function imageDeliveryUrl(imageId: string, transformation = "public") {
  return `${process.env.NEXT_PUBLIC_CF_DELIVERY_URL}${imageId}/${transformation}`
}

const bufferToHex = (buffer) =>
  Array.from(new Uint8Array(buffer))
    .map((x) => x.toString(16).padStart(2, "0"))
    .join("")

async function generateSignedUrl(imageDeliveryURL: string) {
  const { subtle } = require("crypto").webcrypto
  //`url` is a full imagedelivery.net URL
  //e.g. https://imagedelivery.net/cheeW4oKsx5ljh8e8BoL2A/bc27a117-9509-446b-8c69-c81bfeac0a01/mobile
  const url = new URL(imageDeliveryURL)

  const encoder = new TextEncoder()
  const secretKeyData = encoder.encode(process.env.CLOUDFLARE_API_IMAGE_KEY)

  const key = await subtle.importKey(
    "raw",
    secretKeyData,
    { name: "HMAC", hash: "SHA-256" },
    false,
    ["sign"]
  )

  // Attach the expiration value to the `url`
  const expiry = Math.floor(Date.now() / 1000) + EXPIRATION
  url.searchParams.set("exp", expiry.toString())
  // `url` now looks like
  // https://imagedelivery.net/cheeW4oKsx5ljh8e8BoL2A/bc27a117-9509-446b-8c69-c81bfeac0a01/mobile?exp=1631289275

  const stringToSign = url.pathname + "?" + url.searchParams.toString()
  // for example, /cheeW4oKsx5ljh8e8BoL2A/bc27a117-9509-446b-8c69-c81bfeac0a01/mobile?exp=1631289275

  // Generate the signature
  const mac = await subtle.sign("HMAC", key, encoder.encode(stringToSign))
  const sig = bufferToHex(new Uint8Array(mac).buffer)

  // And attach it to the `url`
  url.searchParams.set("sig", sig)

  const urlres = url.toString()

  console.log(urlres)

  return urlres

  // // return new Response(url)
}

const cloudflare = {
  imageDeliveryUrl,
  generateSignedUrl,
}

export default cloudflare
