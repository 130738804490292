import Image, { ImageProps } from "next/legacy/image"
import { cld } from "integrations/cloudinary"
import { NamedTransformationAction } from "@cloudinary/url-gen/actions/namedTransformation/NamedTransformationAction"
import { Photo, PhotoProvider } from "@prisma/client"
import cloudflare from "integrations/cloudflare"

type Props = Omit<ImageProps, "src"> & {
  photo: Photo
  highRes?: string
  transformation: "fos_large_watermark" | "fos_thumb"
  alt?: string
  updatedAt?: string
}

const CloudinaryPhoto = ({
  photo,
  highRes,
  transformation,
  alt,
  updatedAt,
  ...imgProps
}: Props) => {
  const image = cld
    .image(photo.publicId)
    .setDeliveryType("private")
    .namedTransformation(new NamedTransformationAction(transformation))

  const imageUrl = (highRes || image.toURL()) + (updatedAt ? "?" + updatedAt : "")

  // const blurred = cld
  //   .image(publicId)
  //   .setDeliveryType("private")
  //   .namedTransformation(new NamedTransformationAction("fos_blur"))

  const highResProps = highRes
    ? {
        // placeholder: "blur" as const,
        // blurDataURL: blurred.toURL(),
      }
    : {}

  return <Image src={imageUrl} alt={alt || "Foto"} {...imgProps} {...highResProps} quality={100} />
}

const CloudflarePhoto = ({
  photo,
  highRes,
  transformation,
  alt,
  updatedAt,
  ...imgProps
}: Props) => {
  const imageUrl =
    transformation === "fos_large_watermark"
      ? cloudflare.imageDeliveryUrl(photo.watermark ?? "")
      : cloudflare.imageDeliveryUrl(photo.publicId, "thumb")

  return <Image src={imageUrl} alt={alt || "Foto"} {...imgProps} quality={85} />
}

const LocalPhoto = ({ photo }: Props) => {
  return (
    <Image
      src={photo.publicId}
      width={photo.width || 400}
      height={photo.height || 400}
      alt={"Local photo"}
    />
  )
}

export const CloudPhoto = (props: Props) => {
  if (props.photo.provider === PhotoProvider.CLOUDINARY) return <CloudinaryPhoto {...props} />

  if (props.photo.provider === PhotoProvider.CLOUDFLARE) return <CloudflarePhoto {...props} />

  if (props.photo.provider === PhotoProvider.LOCAL) return <LocalPhoto {...props} />

  return <div>{`Unhandled photo provider "${props.photo.provider}"`}</div>
}
