import { CloudPhoto } from "src/app/core/components/CloudPhoto"
import { ProductThumbnailPrint } from "./ProductThumbnail.styles"
import { Photo, Thumbnail } from "@prisma/client"
import { Box } from "@mui/material"

type Props = {
  photo: Photo
  height?: number
  thumbnail?: Thumbnail | null
}

export function ProductThumbnail({
  photo,
  height = 120,
  thumbnail = Thumbnail.PRINT_DEFAULT,
}: Props) {
  const photoEl = (
    <CloudPhoto
      photo={photo}
      updatedAt={photo.updatedAt.toISOString()}
      transformation={"fos_thumb"}
      height={height}
      width={((photo.width || 1) / (photo.height || 1)) * height}
    />
  )
  if (thumbnail === Thumbnail.PRINT_DEFAULT)
    return <ProductThumbnailPrint>{photoEl}</ProductThumbnailPrint>

  if (thumbnail === Thumbnail.PRINT_MINI_9) {
    const miniHeight = height / 3.7
    const miniPhotoEl = (
      <CloudPhoto
        photo={photo}
        updatedAt={photo.updatedAt.toISOString()}
        transformation={"fos_thumb"}
        height={miniHeight}
        width={((photo.width || 1) / (photo.height || 1)) * miniHeight}
      />
    )

    return (
      <ProductThumbnailPrint>
        <Box
          height={height}
          width={((photo.width || 1) / (photo.height || 1)) * height}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          flexWrap={"wrap"}
          position={"relative"}
          sx={{ "&>*:nth-of-type(3n+1), &>*:nth-of-type(3n+2)": { marginRight: "4px!important" } }}
        >
          {miniPhotoEl}
          {miniPhotoEl}
          {miniPhotoEl}
          {miniPhotoEl}
          {miniPhotoEl}
          {miniPhotoEl}
          {miniPhotoEl}
          {miniPhotoEl}
          {miniPhotoEl}
        </Box>
      </ProductThumbnailPrint>
    )
  }

  return photoEl
}
