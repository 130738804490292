import {
  Button,
  InputAdornment,
  styled,
  TextField as MuiTextField,
  TextFieldProps,
} from "@mui/material"
import PlusIcon from "mdi-material-ui/Plus"
import MinusIcon from "mdi-material-ui/Minus"

const StepButton = styled(Button)(({ theme }) => ({
  minWidth: 0,
  padding: 4,
  backgroundColor: theme.palette.grey[200],
  color: theme.palette.grey[900],
  "&:hover": {
    backgroundColor: theme.palette.grey[400],
  },
}))
const TextField = styled(MuiTextField)`
  width: 110px;
  & > div {
    padding-left: 8px;
    padding-right: 8px;
  }
  & input {
    text-align: center;
  }
`

type OwnProps = {
  value: number
  onChange: (value: number) => void
  min?: number
}

export const FormStepField = (props: Omit<TextFieldProps, "onChange" | "value"> & OwnProps) => {
  const decrease = () => {
    props.onChange?.(Math.max(props.min ?? 1, (props.value || 0) - 1))
  }
  const increase = () => {
    props.onChange?.((props.value || 0) + 1)
  }

  return (
    <TextField
      autoComplete="off"
      {...props}
      onChange={(e) => props.onChange?.(parseInt(e.target.value || ""))}
      size="small"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <StepButton onClick={decrease} variant="text">
              <MinusIcon fontSize="small" />
            </StepButton>
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            <StepButton onClick={increase} variant="text">
              <PlusIcon fontSize="small" />
            </StepButton>
          </InputAdornment>
        ),
      }}
    />
  )
}
